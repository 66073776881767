import styled from 'styled-components';
import { Colors } from '../../Foundation/Colors';
import { Fonts } from '../../Foundation/Fonts';

type StyledVariableTextInputProps = {
  isTitle: boolean;
  isWhatsapp: boolean;
  minLines: number;
};

export const StyledVariableTextInput = styled.div<StyledVariableTextInputProps>`
  position: relative;
  margin: ${({ isWhatsapp }) => (isWhatsapp ? '0' : '12px 0 0')};

  ${({ isWhatsapp }) => isWhatsapp && 'width: 100%'};

  .variable-text-input {
    bottom: 8px;
    right: 8px;
  }

  .variable-picker-field--label {
    padding-bottom: 0;
    ${Fonts.link}
    color: ${Colors.grey_blue};
  }

  .Draftail-Toolbar {
    display: none;
  }

  &.error {
    outline: solid 2px ${Colors.error};
    outline-offset: -26px;
  }

  .Draftail-Wrapper {
    padding-top: 2px;
  }

  .public-DraftEditorPlaceholder-root {
    position: relative;
    padding: 0;
  }

  .public-DraftEditorPlaceholder-inner {
    pointer-events: none;
    position: absolute;
    top: 8px;
    left: 8px;
    ${Fonts.bodySmall};
    color: ${Colors.grey_blue_2};
  }

  & .public-DraftEditor-content {
    box-sizing: border-box;
    background-color: ${Colors.grey_blue_0};
    border-radius: 4px/2px;
    width: 100%;
    ${Fonts.bodySmall};
    font-weight: 400;
    line-height: 1.6em;
    min-height: calc(38px + ${(props) => (props.minLines - 1) * 1.6}em);

    div[data-contents] {
      width: 85%;
      padding: 8px 0 4px 8px;
    }

    ::placeholder {
      ${Fonts.bodySmall};
      color: ${Colors.grey_blue_2};
    }

    :focus {
      border-bottom: 2px solid ${Colors.accent};
    }
  }

  .variable-selector {
    position: absolute;
    bottom: 10px;
    right: 10px;
  }
`;
