import React from 'react';
import { ThemeProvider } from '@material-ui/core';
import {
  ContentPreviewModal,
  ContentPreviewModalProps,
} from '../ContentPreviewModal';
import { NovaButton } from '../../Atoms/Navigations';
// It's not ideal to be using this theme here, it's only temporary until we have a global Nova mui theme.
// Otherwise the button loses styles when the modal is open.
import { novaButtonTheme } from '../../Atoms/Navigations/NovaButton/NovaButton.styles';

export type ContentPreviewButtonProps = {
  contentMode: ContentPreviewModalProps['contentMode'];
  mobileContent: ContentPreviewModalProps['mobileContent'];
  desktopContent: ContentPreviewModalProps['desktopContent'];
  subject?: ContentPreviewModalProps['subject'];
  mobileWidth?: ContentPreviewModalProps['mobileWidth'];
  desktopWidth?: ContentPreviewModalProps['desktopWidth'];
  onButtonClicked?: () => void;
  onTabClicked?: (
    previewMode: ContentPreviewModalProps['initialPreviewState']
  ) => void;
  onExitButtonClicked?: () => void;
};

export const ContentPreviewButton = (props: ContentPreviewButtonProps) => {
  const {
    contentMode,
    mobileContent,
    desktopContent,
    subject,
    mobileWidth,
    desktopWidth,
    onTabClicked = () => {},
    onButtonClicked = () => {},
    onExitButtonClicked = () => {},
  } = props;

  const [openState, setOpenState] = React.useState<boolean>(false);

  const handleClick = () => {
    setOpenState(true);
    onButtonClicked();
  };

  return (
    <ThemeProvider theme={novaButtonTheme}>
      <NovaButton variant='secondary' label='Preview' onClick={handleClick} />
      <ContentPreviewModal
        handleClose={() => {
          setOpenState(false);
          onExitButtonClicked();
        }}
        isOpen={openState}
        mobileContent={mobileContent}
        desktopContent={desktopContent}
        contentMode={contentMode}
        subject={subject}
        mobileWidth={mobileWidth}
        desktopWidth={desktopWidth}
        onTabClicked={onTabClicked}
      />
    </ThemeProvider>
  );
};
