import React from 'react';

import { Icons } from '../../Foundation/Icons';

export const allIcons = [
  'Accordian',
  'AccountCircle',
  'AccountCircleOutline',
  'Add',
  'AddToCalendar',
  'AddToList',
  'AlertUser',
  'AlignmentLeft',
  'AlignmentMiddle',
  'AlignmentRight',
  'Analytics',
  'AnalyticsOutline',
  'Api',
  'ApiKeys',
  'Archive',
  'ArrowRight',
  'Assessment',
  'Banner',
  'Billing',
  'Branch',
  'BranchContainer',
  'BranchDiamond',
  'BulletedList',
  'Button',
  'ButtonGroup',
  'Calendar',
  'Checkbox',
  'CheckboxList',
  'ChevronLeft',
  'ChevronRight',
  'Close',
  'CloseCircle',
  'CloudUpload',
  'Code',
  'Cog',
  'Columns',
  'Comment',
  'Company',
  'Condition',
  'ContactPortal',
  'ContactPrivacy',
  'Contacts',
  'ContactsOutline',
  'Conversations',
  'Copy',
  'CustomCode',
  'CustomModule',
  'DailyMotion',
  'Date',
  'Decrease',
  'DefaultTrigger',
  'Delete',
  'DeleteBranch',
  'Desktop',
  'Devices',
  'Disengaged',
  'Divider',
  'Dkim',
  'Dot',
  'DownArrow',
  'DragHandle',
  'DropDown',
  'DropdownArrow',
  'DropDownList',
  'DropupArrow',
  'EditPen',
  'EditPenOutline',
  'Elipsis',
  'Email',
  'EmailDisabled',
  'EmailOutline',
  'EmailToSms',
  'EmailTrigger',
  'Engaged',
  'Error',
  'ErrorOutline',
  'Exit',
  'ExpandLess',
  'ExpandMore',
  'Export',
  'Eye',
  'Facebook',
  'Filter',
  'Footer',
  'FormTrigger',
  'Futuro',
  'HelpWheel',
  'Home',
  'HostedMP4',
  'IconAlignmentLeft',
  'IconAlignmentRight',
  'Image',
  'ImageCopy',
  'Increase',
  'Info',
  'InfoOutline',
  'Iux',
  'Launch',
  'Lightning',
  'Link',
  'LinkOut',
  'Location',
  'LocationOutline',
  'Lock',
  'Logo',
  'LogoBeta',
  'LogoDots',
  'LoneWorker',
  'LongText',
  'Manual',
  'ManualTrigger',
  'Map',
  'Mapping',
  'Medical',
  'Meeting',
  'Menu',
  'Message',
  'MessageDisabled',
  'Messages',
  'MessagesOutline',
  'MessangerInactive',
  'Metrics',
  'Mms',
  'Mobile',
  'Move',
  'Multilingual',
  'NewRelease',
  'NumberedList',
  'Notifications',
  'Onboarding',
  'Open',
  'ParagraphAlignCentre',
  'ParagraphAlignLeft',
  'ParagraphAlignRight',
  'PlaceholderImage',
  'Person',
  'Plus',
  'Processing',
  'Publish',
  'Push',
  'Pushie',
  'QuestionOutline',
  'RadioList',
  'Rating',
  'Received',
  'Recipients',
  'Remove',
  'RemoveFromList',
  'Reply',
  'ReplyOutline',
  'Rostering',
  'Rss',
  'SalesForceTrigger',
  'Schedule',
  'Search',
  'ServiceNow',
  'Signature',
  'Slider',
  'SmartReview',
  'SmsOutline',
  'SMSTrigger',
  'Social',
  'SocialNew',
  'Sort',
  'SortArrows',
  'Spf',
  'Spinner',
  'Sso',
  'StatusDownDark',
  'StatusDownLight',
  'StatusTick',
  'StatusUpDark',
  'StatusUpLight',
  'Store',
  'Submit',
  'SubscribedList',
  'SystemSettings',
  'Templates',
  'Text',
  'TextAndImage',
  'TextField',
  'TextInput',
  'ThumbsDown',
  'ThumbsUp',
  'Tick',
  'Tip',
  'TokBox',
  'ToneOfVoice',
  'Training',
  'UnderConstruction',
  'UpArrow',
  'Upgrade',
  'Upload',
  'UploadSuccess',
  'Video',
  'Vimeo',
  'Voice',
  'VoiceInactive',
  'VoiceOutline',
  'Warning',
  'WarningOutline',
  'Web',
  'WebForm',
  'WebformTrigger',
  'WebformTrigger',
  'WebhookTrigger',
  'WebOutline',
  'Whatsapp',
  'WhatsappInactive',
  'Wistia',
  'Workflow',
  'WorkflowOutline',
  'YesNo',
  'Youtube',
] as const;

export type IconTypes = typeof allIcons[number];

type IconProps = {
  icon: IconTypes;
  className?: string;
  [key: string]: unknown;
};

export const Icon = (props: IconProps) => {
  const { icon, className = '', ...rest } = props;
  const element = React.createElement(Icons[icon], {
    className: `Icon ${className}`,
    ...rest,
  });
  return element;
};
