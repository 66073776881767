/* eslint-disable @typescript-eslint/camelcase */
import { ColorTypes } from './Colors.types';

export const Colors: Record<ColorTypes, string> = {
  // Accent Colors
  accent_1: '#F6F6FF',
  accent_2: '#E3E3FF',
  accent_3: '#DADAFF',
  accent_4: '#C7C7FF',
  accent_4_5: '#8E8EF8',
  accent_5: '#4D4DFF',
  accent: '#1515FF',
  accent_dark: '#0F0FC2',
  accent_dnd: '#8E44AD',
  background_dark_01: '#1A1A4F',

  // Neutral Colors
  white: '#FFF',
  background: '#F2F4F7',
  grey_blue_0: '#F6F6F7',
  grey_blue_1: '#E7E7E9',
  grey_blue_2: '#C3C3C8',
  grey_blue_3: '#9FA0A7',
  grey_blue_4: '#70707B',
  grey_blue_5: '#343A40',
  grey_blue: '#1D1E2E',
  background_dark: '#030315',
  black: '#000',

  // Semantic Colors
  success: '#45DB81',
  success_dark: '#188142',
  success_light: '#ECFBF2',
  error: '#D30D28',
  error_light: '#FCE7EA',
  warning: '#F6C604',
  warning_dark: '#876D03',
  warning_light: '#FEF9E6',

  // Channel Colors
  email: '#F36B31',
  email_dark: '#C1420B',
  email_light: '#FEF0EA',
  sms: '#00CDC6',
  sms_dark: '#007A76',
  sms_light: '#E5FAF9',
  web: '#F6C604',
  voice: '#8843DF',
  whatsapp: '#25D366',
  whatsapp_sent: '#E69F00',
  whatsapp_sent_light: '#FDF5E5',
  whatsapp_replied: '#0072B2',
  whatsapp_replied_light: '#E5F1F7',
  whatsapp_received: '#CC79A7',
  whatsapp_received_light: '#F0D7E5',

  // Miscellaneous Colors
  variable: '#7F32DC',
  variable_light: '#E7DAF9',
  pink: '#FF2C8D',
  blue: '#008EFA',
  blue_light: '#DEECFF',
  green_light: '#50D19C',

  // Status Colors
  received: '#3636FF',
  failed: '#C1420B',
  failed_light: '#F9ECE7',
  read: '#4AB0AD',
  read_light: '#EDF7F7',
};
