import React from 'react';
import { WebPreviewer } from '../../Atoms/WebPreviewer';
import { Subject } from '../Subject/Subject';
import { DesktopPreviewTypes } from './DesktopPreview.types';
import { DesktopPreviewWrapper } from './DesktopPreview.styles';

export const DesktopPreview = ({
  content,
  desktopWidth,
  desktopHeight,
  subject,
  enableScale,
}: DesktopPreviewTypes): JSX.Element => (
  <DesktopPreviewWrapper
    hasSubject={!!subject}
    width={desktopWidth}
    height={desktopHeight}
  >
    <div className='header'>
      <div />
      <div />
      <div />
    </div>
    {/* render three circles */}
    {subject && <Subject subject={subject} />}
    <div className='content'>
      <WebPreviewer
        enableScale={enableScale}
        content={content}
        deviceWidth={desktopWidth}
        deviceHeight={desktopHeight}
      />
    </div>
  </DesktopPreviewWrapper>
);
