import React, { useCallback } from 'react';

import { TableCell, TableRow as MUITableRow } from '@material-ui/core';
import { PopoverMenuSimpleIcon } from '../../Molecules/PopoverMenuSimpleIcon';

import { Checkbox } from '../../Atoms/Forms';
import { ResponsiveLabel } from '../../Molecules/ResponsiveLabel';
import { typedMemo } from '../../Utils/typedMemo';
import {
  ActionButtonProps,
  RowData,
  QueryDataTableRowProps,
  DefaultRenderMethodOptions,
} from './QueryDataTable.types';

function changeToResponsiveLabel(
  text: string,
  { truncateLongText, maxWidth }: DefaultRenderMethodOptions
) {
  if (truncateLongText && maxWidth) {
    return (
      <ResponsiveLabel
        className='truncatedable'
        text={text}
        maxWidth={maxWidth}
      />
    );
  }

  return text;
}

/**
 * Default render method if renderCell isn't provided.
 * @param data
 */
const defaultRenderMethod = (
  data: unknown,
  options: DefaultRenderMethodOptions
) => {
  if (typeof data === 'number') {
    return data;
  }

  if (typeof data === 'string') {
    return changeToResponsiveLabel(data, options);
  }

  if (data instanceof Date) {
    return changeToResponsiveLabel(data.toISOString(), options);
  }

  return JSON.stringify(data);
};

const ActionButton = <T extends RowData>(props: ActionButtonProps<T>) => {
  const { actions, rowData, ariaLabel } = props;

  return (
    <>
      <PopoverMenuSimpleIcon
        ariaLabel={ariaLabel}
        icon='Elipsis'
        menuItems={actions
          .filter((action) => {
            const { check } = action;
            let renderAction = true;

            if (typeof check === 'function') renderAction = check(rowData);
            if (renderAction) return action;
          })
          .map(({ action, label }) => {
            return {
              label,
              onClick: () => {
                action(rowData);
              },
            };
          })}
      />
    </>
  );
};

export const TableRow = typedMemo(
  <T extends RowData>(props: QueryDataTableRowProps<T>) => {
    const {
      rowData,
      isSelected,
      selectMode,
      onRowClick,
      columns,
      rowActions,
      selectOnRowClick,
      isRowDisabled,
    } = props;
    const handleRowClick = useCallback(() => {
      onRowClick(rowData);
    }, [onRowClick, rowData]);

    let rowStyle = isSelected ? 'row-selected' : '';

    if (isRowDisabled) {
      rowStyle = 'row-disabled';
    }

    return (
      <MUITableRow
        key={rowData.id}
        onClick={() => (selectOnRowClick ? handleRowClick() : null)}
        className={rowStyle}
        hover={!isRowDisabled}
      >
        {selectMode === 'multi' && (
          <TableCell className='checkbox-cell'>
            <Checkbox onChange={handleRowClick} isChecked={isSelected} />
          </TableCell>
        )}

        {columns.map((columnData) => {
          const { field, renderCell, truncateLongText, maxWidth } = columnData;
          const cellData = rowData[columnData.field];

          return (
            <TableCell
              key={`${rowData.id}-${field}`}
              className={isRowDisabled ? 'disabled-cell' : `${field}-cell`}
            >
              {renderCell
                ? renderCell(cellData, rowData)
                : defaultRenderMethod(cellData, { truncateLongText, maxWidth })}
            </TableCell>
          );
        })}

        {rowActions.length > 0 && (
          <TableCell
            aria-label={`${rowData.id}-action-cell`}
            className='row-action-cell'
          >
            <ActionButton
              actions={rowActions}
              rowData={rowData}
              ariaLabel={`${rowData.id} action button`}
            />
          </TableCell>
        )}
      </MUITableRow>
    );
  }
);
