import styled from 'styled-components';
import { Colors } from '../../Foundation/Colors/Colors';
import { Fonts } from '../../Foundation/Fonts/Fonts';

export const SubjectWrapper = styled.div<{ height: number }>`
  ${Fonts.caption};
  font-weight: 400;
  display: flex;
  align-items: baseline;
  height: ${({ height }) => height}px;
  box-sizing: border-box;
  border-bottom: 1px solid ${Colors.grey_blue_1};
  color: ${Colors.grey_blue};
  padding-left: 24px;
  padding-top: 12px;
`;

export const SubjectLine = styled.span`
  ${Fonts.link};
`;
