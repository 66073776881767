import React, { useState, useEffect, useRef } from 'react';
import { WebPreviewerTypes } from './WebPreviewer.types';
import { WebPreviewerWrapper } from './WebPreviewer.styles';

export const WebPreviewer = ({
  content,
  deviceWidth,
  deviceHeight,
  enableScale = false,
}: WebPreviewerTypes): JSX.Element => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [style, setStyle] = useState({});

  useEffect(() => {
    if (!contentRef || !contentRef.current) return;

    const getScale = () => {
      if (deviceWidth) return deviceWidth / window.innerWidth;
      if (!contentRef || !contentRef.current) return 1;
      const { width } = contentRef.current.getBoundingClientRect();
      return width / window.innerWidth;
    };
    const scale = getScale();
    const width = deviceWidth
      ? deviceWidth / scale
      : contentRef.current.getBoundingClientRect().width / scale;
    const height = deviceHeight
      ? deviceHeight / scale
      : contentRef.current.getBoundingClientRect().height / scale;

    setStyle({
      width: enableScale ? width : deviceWidth || '100%',
      height: enableScale ? height : deviceHeight || '100%',
      transform: enableScale ? `scale(${scale})` : '',
      transformOrigin: 'top left',
      overflow: 'auto',
    });
  }, [contentRef, deviceWidth, deviceHeight, enableScale]);

  return (
    <WebPreviewerWrapper ref={contentRef}>
      <div style={style}>{content}</div>
    </WebPreviewerWrapper>
  );
};
