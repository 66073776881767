import React, { forwardRef, InputHTMLAttributes } from 'react';
import { Control, FieldErrors, Path } from 'react-hook-form';
import { GenericFormFieldAttributes } from '../../ListForm.types';
import { StyledPhoneInput } from '../../ListForm.styles';
import { GenericTextField } from '../GenericTextField';
import { CountrySelect } from './CountrySelect/CountrySelect';

// TODO: get default country of user, from company/account settings?
export const DEFAULT_COUNTRY = 'AU' as const;

type PhoneNumberFieldProps<TFormDataType> = {
  errors: FieldErrors<TFormDataType>;
  name: Path<TFormDataType>;
  control: Control<TFormDataType>;
} & Omit<GenericFormFieldAttributes<TFormDataType>, 'fieldComponent'>;

type ForwardPhoneInputComponentType<
  TFormDataType
> = React.ForwardRefExoticComponent<
  InputHTMLAttributes<HTMLInputElement> & {
    inputProps: Omit<PhoneNumberFieldProps<TFormDataType>, 'control'>;
  }
>;

// React.forwardRef doesn't accept generic types due to TS issue/limitation related to higher order function type inference
// https://github.com/microsoft/TypeScript/issues/30650#issuecomment-478258803
const ForwardPhoneInputComponent: ForwardPhoneInputComponentType<{}> = forwardRef(
  ({ inputProps, ...props }, forwardRef) => (
    <GenericTextField inputRef={forwardRef} {...props} {...inputProps} />
  )
);

export const PhoneNumberField = <TFormDataType,>({
  name,
  control,
  ...rest
}: PhoneNumberFieldProps<TFormDataType>): JSX.Element => (
  <StyledPhoneInput
    key={name}
    name={name}
    control={control}
    defaultCountry={DEFAULT_COUNTRY}
    inputComponent={ForwardPhoneInputComponent}
    numberInputProps={{
      inputProps: { name, ...rest },
    }}
    // TODO: do we want to have a different list order depending on the user's location?
    countryOptionsOrder={['AU', 'US', 'NZ', 'SG', '|', '...']}
    countrySelectComponent={CountrySelect}
    displayInitialValueAsLocalNumber
  />
);
