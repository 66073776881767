import {
  createMuiTheme,
  styled,
  Button as MUIButton,
  ButtonProps as MUIButtonProps,
} from '@material-ui/core';
import { Colors } from '../../../Foundation/Colors';

export const palette = {
  primary: {
    main: Colors.accent,
    light: Colors.accent_2,
    dark: Colors.background_dark_01,
  },
  secondary: {
    main: '#EBEAED',
    light: Colors.grey_blue_0,
    dark: Colors.grey_blue_4,
  },
};

export const novaButtonTheme = createMuiTheme({
  palette,
  typography: {
    button: {
      textTransform: 'none',
    },
  },
  shape: {
    borderRadius: 22,
  },
});

export const StyledButton = styled(MUIButton)<MUIButtonProps>(({ theme }) => ({
  borderColor: Colors.accent,
  borderRadius: '22px',

  '&:hover': {
    backgroundColor:
      theme.variant === 'contained' ? Colors.accent_dark : Colors.accent_1,
  },
  '&:active': {
    borderColor: Colors.accent_3,
    backgroundColor:
      theme.variant === 'contained' ? Colors.accent : Colors.accent_3,
  },
}));
