/* This code is based on the components in CountrySelect.js from react-phone-number-input:
 * https://gitlab.com/catamphetamine/react-phone-number-input/blob/master/source/CountrySelect.js
 * which are used as default for the prop countrySelectComponent in PhoneInputWithCountry.
 */

import React, { useCallback, useEffect, useState } from 'react';
import { MenuItem } from '@material-ui/core';
import { CountryCode, getCountryCallingCode } from 'libphonenumber-js';
import * as S from './CountrySelect.styles';

export const CountrySelect = ({
  value,
  onChange,
  options,
  iconComponent: IconComponent,
  ...rest
}) => {
  const [countryCodeMap, setCountryCodeMap] = useState<
    Record<CountryCode, string>
  >();

  // for constant O(1) lookup times, we reduce the options to a hashmap on first render,
  // for getting the label for current the value. An option to avoid this reduce is to create
  // a static country code/label map that we maintain internally.
  useEffect(() => {
    const optionsReduce = options.reduce(
      (
        acc: Record<CountryCode, string>,
        current: { value?: string; label: string } | { divider: boolean }
      ) => {
        if (!('divider' in current)) {
          if (current.value) {
            acc[current.value] = current.label;
          } else {
            acc['undefined'] = current.label;
          }
        }
        return acc;
      },
      {} as Record<CountryCode, string>
    );
    setCountryCodeMap(optionsReduce);
  }, []);

  const selectedOption = countryCodeMap && countryCodeMap[value];

  const onChangeCallback = useCallback(
    (event) => {
      const { value } = event.target;
      onChange(value === 'ZZ' ? undefined : value);
    },
    [onChange]
  );

  return (
    <S.StyledSelect
      variant='outlined'
      {...rest}
      aria-label='Country Code select'
      value={value || 'ZZ'}
      onChange={onChangeCallback}
      renderValue={() => (
        <S.CountrySelectValueWrapper
          aria-label={`Selected Country Code: ${value || 'ZZ'}`}
        >
          <IconComponent
            country={value}
            label={selectedOption ? selectedOption.label || '' : ''}
          />
          {value ? `+${getCountryCallingCode(value)}` : ''}
        </S.CountrySelectValueWrapper>
      )}
    >
      {options.map(({ value, label, divider }) => {
        return (
          <MenuItem
            key={divider ? '|' : value || 'ZZ'}
            value={divider ? '|' : value || 'ZZ'}
            disabled={!!divider}
            style={divider ? S.DIVIDER_STYLE : undefined}
          >
            <div>{label}</div>
          </MenuItem>
        );
      })}
    </S.StyledSelect>
  );
};
