import styled from 'styled-components';
import { Colors } from '../../Foundation/Colors';
import { SUBJECT_HEIGHT } from '../Subject';

const desktopHeaderHeight = 42;

export const DesktopPreviewWrapper = styled.div<{
  hasSubject: boolean;
  height?: number;
  width?: number;
}>`
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  height: ${({ height }) => (height ? `${height}px` : '100%')};
  outline: 1px solid ${Colors.grey_blue_2};
  border-radius: 8px;
  box-sizing: border-box;
  margin: 0 auto;

  .header {
    display: flex;
    align-items: center;
    height: ${desktopHeaderHeight}px;
    border-bottom: 1px solid ${Colors.grey_blue_2};
    gap: 8px;
    padding-left: 16px;

    div {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      border: 1px solid ${Colors.grey_blue_2};
    }
  }

  .content {
    width: 100%;
    height: calc(
      100% -
        ${({ hasSubject }) =>
          hasSubject
            ? desktopHeaderHeight + SUBJECT_HEIGHT
            : desktopHeaderHeight}px
    );
    border-radius: 0 0 8px 8px;
    overflow: hidden;
  }
`;
