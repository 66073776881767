import React, { useEffect, useCallback } from 'react';

import {
  operatorIds,
  variableIds,
  constantGenerators,
} from '@whispir/expression-helper';
import {
  OperatorConfiguration,
  WrappedComponentProps,
  OperatorLabel,
  ConditionProps,
} from '../../definitions/operatorAndOperand';
import { TextField, Dropdown, DatePicker } from '../../../../Atoms/Forms';
import { BetweenDateComponent } from './dateComponents/BetweenDateComponent';
import { BetweenNumberComponent } from './numberComponents/BetweenNumberComponent';
import { WithinRangeDateComponent } from './dateComponents/WithinRangeDateComponent';
import { dateToISOString, formatDate } from './dateComponents/utils';
import { StyledExpressionLabel } from './baseOperandComponents.style';

const {
  EQUALS,
  NOT_EQUALS,
  CONTAINS,
  ARRAY_CONTAINS,
  ARRAY_NOT_CONTAINS,
  NOT_CONTAINS,
  STARTS_WITH,
  NOT_STARTS_WITH,
  ENDS_WITH,
  NOT_ENDS_WITH,
  BEFORE,
  AFTER,
  BETWEEN,
  IN_THE_NEXT,
  HAS_PASSED,
  LESS_THAN,
  GREATER_THAN,
} = operatorIds;

const { STRING, NUMBER, DATE } = variableIds;

const {
  generateStringConstant,
  generateDateConstant,
  generateNumberConstant,
} = constantGenerators;

export const WrappedNumberTextField = ({
  defaultValue,
  onChange,
}: WrappedComponentProps): JSX.Element => {
  useEffect(() => {
    onChange(defaultValue);
  }, []);

  return (
    <TextField
      autoFocus
      inputProps={{
        min: '0',
      }}
      type='number'
      placeholder='Enter value'
      onChange={(value) => onChange(generateNumberConstant({ value }))}
      defaultValue={
        defaultValue && defaultValue.valueType === NUMBER
          ? defaultValue.value
          : ''
      }
    />
  );
};

export const WrappedTextField = ({
  defaultValue,
  onChange,
}: WrappedComponentProps): JSX.Element => {
  useEffect(() => {
    onChange(defaultValue);
  }, []);

  return (
    <TextField
      autoFocus
      placeholder='Enter value'
      onChange={(value) => onChange(generateStringConstant({ value }))}
      defaultValue={
        defaultValue && defaultValue.valueType === STRING
          ? defaultValue.value
          : ''
      }
      helperText='Separate multiple values with a comma'
    />
  );
};

export const WrappedDropdown = ({
  defaultValue,
  onChange,
  options,
}: WrappedComponentProps & { options: Array<OperatorLabel> }): JSX.Element => {
  const foundValue =
    defaultValue && options
      ? options.find(({ value }) => value === defaultValue.value)
      : null;
  const actualFoundValue = foundValue ? foundValue.value : null;

  useEffect(() => {
    onChange(defaultValue);
  }, []);
  return (
    <Dropdown
      autoFocus
      options={options}
      value={actualFoundValue}
      onChange={(value) => {
        onChange(generateStringConstant({ value }));
      }}
    />
  );
};

export const WrappedDatePicker = ({
  defaultValue,
  onChange,
}: WrappedComponentProps): JSX.Element => {
  const valueToUse =
    defaultValue && defaultValue.valueType === DATE ? defaultValue.value : null;

  const handleChange = useCallback(
    (value) => {
      onChange(generateDateConstant({ value: dateToISOString(value) }));
    },
    [onChange]
  );

  useEffect(() => {
    handleChange(valueToUse ? new Date(valueToUse) : null);
  }, []);

  return (
    <DatePicker onChange={handleChange} defaultValue={valueToUse} autoFocus />
  );
};

export const operatorLabelObjects: { [key: string]: OperatorLabel } = {
  [EQUALS]: { value: EQUALS, label: 'equals' },
  [NOT_EQUALS]: { value: NOT_EQUALS, label: 'doesn’t equal' },
  [ARRAY_CONTAINS]: { value: ARRAY_CONTAINS, label: 'contains' },
  [ARRAY_NOT_CONTAINS]: { value: ARRAY_NOT_CONTAINS, label: 'doesn’t contain' },
  [CONTAINS]: { value: CONTAINS, label: 'contains' },
  [NOT_CONTAINS]: { value: NOT_CONTAINS, label: 'doesn’t contain' },
  [STARTS_WITH]: { value: STARTS_WITH, label: 'starts with' },
  [NOT_STARTS_WITH]: { value: NOT_STARTS_WITH, label: 'doesn’t start with' },
  [ENDS_WITH]: { value: ENDS_WITH, label: 'ends with' },
  [NOT_ENDS_WITH]: { value: NOT_ENDS_WITH, label: 'doesn’t end with' },
  [LESS_THAN]: { value: LESS_THAN, label: 'less than' },
  [GREATER_THAN]: { value: GREATER_THAN, label: 'more than' },
  [BEFORE]: { value: BEFORE, label: 'is before' },
  [AFTER]: { value: AFTER, label: 'is after' },
  [BETWEEN]: { value: BETWEEN, label: 'is between' },
  [IN_THE_NEXT]: { value: IN_THE_NEXT, label: 'in the next' },
  [HAS_PASSED]: { value: HAS_PASSED, label: 'has passed' },
};

const defaultLabelOptions = {
  dateFormat: 'dd/MM/yyyy',
};

export const createExpressionLabel = ({
  operator,
  operand,
  dateFormat = defaultLabelOptions.dateFormat,
}: ConditionProps & { dateFormat?: string }): JSX.Element => {
  if (!operator) {
    return <StyledExpressionLabel />;
  }

  switch (operator) {
    case BETWEEN: {
      /**
       * default case when operand is null, display the operator label.
       * The 'operand' object will get created as part of <ConditionPicker /> component lifecycle
       *  */

      if (!operand) {
        const { label: operatorLabel } = operatorLabelObjects[operator];
        return <StyledExpressionLabel>{operatorLabel}</StyledExpressionLabel>;
      }
      switch (operand.valueType) {
        case DATE: {
          const afterValue = operand && operand.value;
          let beforeValue;

          if (operand.context && 'before' in operand.context) {
            beforeValue = operand.context.before.value;
          }

          const afterValueFormatted =
            (afterValue && formatDate(afterValue, dateFormat)) || '';
          const beforeValueFormatted =
            (beforeValue && formatDate(beforeValue, dateFormat)) || '';
          return (
            <StyledExpressionLabel>
              is between <span className='value'>{afterValueFormatted}</span>
              {afterValueFormatted && ' and '}
              <span className='value'>{beforeValueFormatted}</span>
            </StyledExpressionLabel>
          );
        }
        case NUMBER: {
          const greaterThanValue = operand && operand.value;
          let lessThanValue;

          if ('lessThan' in operand.context) {
            lessThanValue = operand.context.lessThan.value;
          }

          return (
            <StyledExpressionLabel>
              is between <span className='value'>{greaterThanValue}</span>
              {greaterThanValue && ' and '}
              <span className='value'>{lessThanValue}</span>
            </StyledExpressionLabel>
          );
        }
        default:
          // return an empty string with operator label
          return (
            <StyledExpressionLabel>
              {operatorLabelObjects[operator].label}
            </StyledExpressionLabel>
          );
      }
    }
    /**
     *  IN_THE_NEXT and HAS_PASSED switch cases code could be cleaned up
     */
    case IN_THE_NEXT: {
      if (operand.context && 'durationUnit' in operand.context) {
        const {
          context: { durationUnit },
        } = operand;
        const durationValue = operand && operand.value;
        const durationFormatted =
          durationValue !== '1'
            ? `${durationValue} ${durationUnit}s`
            : durationUnit;
        return (
          <StyledExpressionLabel>
            {'is in the next '}
            <span className='value'>{durationFormatted}</span>
          </StyledExpressionLabel>
        );
      }
      return (
        <StyledExpressionLabel>
          {operatorLabelObjects[operator].label}
        </StyledExpressionLabel>
      );
    }
    case HAS_PASSED: {
      if (operand.context && 'durationUnit' in operand.context) {
        const {
          context: { durationUnit },
        } = operand;
        const durationValue = operand && operand.value;
        const durationUnitFormatted =
          durationValue !== '1' ? ` ${durationUnit}s` : ` ${durationUnit}`;
        const operatorLabelForamated =
          durationValue !== '1' ? 'have passed' : 'has passed';
        return (
          <StyledExpressionLabel>
            {durationValue}
            {durationValue && durationUnitFormatted} {operatorLabelForamated}
          </StyledExpressionLabel>
        );
      }
      return (
        <StyledExpressionLabel>
          {operatorLabelObjects[operator].label}
        </StyledExpressionLabel>
      );
    }
    case EQUALS:
    case ARRAY_CONTAINS:
    case ARRAY_NOT_CONTAINS:
    case NOT_EQUALS:
    case CONTAINS:
    case NOT_CONTAINS:
    case STARTS_WITH:
    case NOT_STARTS_WITH:
    case ENDS_WITH:
    case NOT_ENDS_WITH:
    case BEFORE:
    case GREATER_THAN:
    case LESS_THAN:
    case AFTER: {
      const { label: operatorLabel } = operatorLabelObjects[operator];

      if (!operand) {
        return <StyledExpressionLabel>{operatorLabel}</StyledExpressionLabel>;
      }
      const { value, valueType } = operand;
      let operandValueLabel;
      if (value) {
        switch (valueType) {
          case DATE:
            operandValueLabel = formatDate(value, dateFormat);
            break;
          case NUMBER:
          case STRING:
            operandValueLabel = `“${value}”`;
            break;
          default:
            throw Error(`Invalid valueType: ${valueType}`);
        }
      } else {
        operandValueLabel = '';
      }

      return (
        <StyledExpressionLabel>
          {operatorLabel} <span className='value'>{operandValueLabel}</span>
        </StyledExpressionLabel>
      );
    }
    default:
      throw new Error(`Invalid operator: ${operator}`);
  }
};

export const textfieldOperatorComponents: OperatorConfiguration = {
  [EQUALS]: {
    ...operatorLabelObjects[EQUALS],
    component: WrappedTextField,
  },
  [NOT_EQUALS]: {
    ...operatorLabelObjects[NOT_EQUALS],
    component: WrappedTextField,
  },
  [CONTAINS]: {
    ...operatorLabelObjects[CONTAINS],
    component: WrappedTextField,
  },
  [NOT_CONTAINS]: {
    ...operatorLabelObjects[NOT_CONTAINS],
    component: WrappedTextField,
  },
  [STARTS_WITH]: {
    ...operatorLabelObjects[STARTS_WITH],
    component: WrappedTextField,
  },
  [NOT_STARTS_WITH]: {
    ...operatorLabelObjects[NOT_STARTS_WITH],
    component: WrappedTextField,
  },
  [ENDS_WITH]: {
    ...operatorLabelObjects[ENDS_WITH],
    component: WrappedTextField,
  },
  [NOT_ENDS_WITH]: {
    ...operatorLabelObjects[NOT_ENDS_WITH],
    component: WrappedTextField,
  },
};

export const optionOperandComponents: OperatorConfiguration = {
  [EQUALS]: {
    ...operatorLabelObjects[EQUALS],
    component: WrappedDropdown,
  },
  [NOT_EQUALS]: {
    ...operatorLabelObjects[NOT_EQUALS],
    component: WrappedDropdown,
  },
};

export const multiSelectOperandComponents: OperatorConfiguration = {
  [ARRAY_CONTAINS]: {
    ...operatorLabelObjects[ARRAY_CONTAINS],
    component: WrappedDropdown,
  },
  [ARRAY_NOT_CONTAINS]: {
    ...operatorLabelObjects[ARRAY_NOT_CONTAINS],
    component: WrappedDropdown,
  },
};

export const dateOperandComponents: OperatorConfiguration = {
  [EQUALS]: {
    ...operatorLabelObjects[EQUALS],
    component: WrappedDatePicker,
  },
  [BEFORE]: {
    ...operatorLabelObjects[BEFORE],
    component: WrappedDatePicker,
  },
  [AFTER]: {
    ...operatorLabelObjects[AFTER],
    component: WrappedDatePicker,
  },
  [BETWEEN]: {
    ...operatorLabelObjects[BETWEEN],
    component: BetweenDateComponent,
  },
  [IN_THE_NEXT]: {
    ...operatorLabelObjects[IN_THE_NEXT],
    component: WithinRangeDateComponent,
  },
  [HAS_PASSED]: {
    ...operatorLabelObjects[HAS_PASSED],
    component: WithinRangeDateComponent,
  },
};

export const numberOperandComponents: OperatorConfiguration = {
  [EQUALS]: {
    ...operatorLabelObjects[EQUALS],
    component: WrappedNumberTextField,
  },
  [NOT_EQUALS]: {
    ...operatorLabelObjects[NOT_EQUALS],
    component: WrappedNumberTextField,
  },
  [LESS_THAN]: {
    ...operatorLabelObjects[LESS_THAN],
    component: WrappedNumberTextField,
  },
  [GREATER_THAN]: {
    ...operatorLabelObjects[GREATER_THAN],
    component: WrappedNumberTextField,
  },
  [BETWEEN]: {
    ...operatorLabelObjects[BETWEEN],
    component: BetweenNumberComponent,
  },
};
