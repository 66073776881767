import React, { useState } from 'react';
import { AppBar, Box, Tab, Tabs, Toolbar } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { Icons } from '../../../Foundation/Icons';
import { IconButton } from '../../../Atoms/Navigations/IconButton';
import { SPARX_PAGES, TopNavBarProps } from './TopNavBar.types';
import { StyledTopNavBarContainer } from './TopNavBar.styles';

export const TopNavBar = ({
  navLinks = SPARX_PAGES,
  actions = [
    {
      icon: 'Add',
    },
    {
      icon: 'QuestionOutline',
    },
    {
      icon: 'Notifications',
    },
    {
      icon: 'AccountCircle',
    },
  ],
  customActions,
  onClickLink,
  onClickLogo,
  currentPage,
}: TopNavBarProps) => {
  const [value, setTabIndex] = useState(
    SPARX_PAGES.findIndex(({ title }) => title === currentPage.title)
  );
  const history = useHistory();

  const handleClickLogo = () => {
    if (onClickLogo) {
      onClickLogo();
    } else {
      history.push('/');
    }
  };

  const handleChange = (_, selectedTabIndex) => {
    // TODO: check with NextJS
    history.push(navLinks[selectedTabIndex].pathname);

    if (onClickLink) {
      onClickLink(SPARX_PAGES[selectedTabIndex]);
    }

    setTabIndex(selectedTabIndex);
  };

  return (
    <StyledTopNavBarContainer>
      <AppBar position='fixed' elevation={0}>
        <Toolbar>
          <Box
            width='100%'
            height='100%'
            display='flex'
            justifyContent='space-between'
            alignItems='center'
          >
            <Box
              height='100%'
              display='flex'
              justifyContent='center'
              alignItems='center'
            >
              <div
                role='button'
                className='logo-container'
                onClick={handleClickLogo}
                aria-label='whispir logo'
              >
                <Box mr={4} className='futoro-logo'>
                  <Icons.FuturoIcon />
                </Box>
                <Box mr={4} className='whispir-logo'>
                  <Icons.Logo />
                </Box>
              </div>
              <Tabs
                className='topnavbar-tabs'
                value={value}
                onChange={handleChange}
              >
                {navLinks.map(({ title }) => (
                  <Tab key={title} label={title} />
                ))}
              </Tabs>
            </Box>
            <Box
              height='100%'
              display='flex'
              justifyContent='center'
              alignItems='center'
            >
              {customActions ||
                actions.map(({ icon, onClick }) => (
                  <IconButton key={icon} icon={icon} onClick={onClick} />
                ))}
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
    </StyledTopNavBarContainer>
  );
};
