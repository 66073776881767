import { Container } from '@material-ui/core';
import styled from 'styled-components';
import { navBarHeight } from './TopNavBar/TopNavBar.styles';

export const StyledMenuNavigation = styled(Container)`
  &.MuiContainer-root {
    padding: 0;
  }

  .navigation-content {
    display: flex;
    flex-grow: 1;
    margin-top: ${navBarHeight}px;
    height: calc(100vh - ${navBarHeight}px);
  }
`;
