import React, { useRef, useState, useEffect } from 'react';
import Editor from '@monaco-editor/react';
import { TabComponent } from '../../Molecules/TabComponent';
import { Button } from '../Navigations/Button';
import { CodeEditorWrapper } from './CodeEditor.styles';

enum TrackedRangeStickiness {
  AlwaysGrowsWhenTypingAtEdges = 0,
  NeverGrowsWhenTypingAtEdges = 1,
  GrowsOnlyWhenTypingBefore = 2,
  GrowsOnlyWhenTypingAfter = 3,
}

type FindMatch = {
  range: {
    startLineNumber: number;
    startColumn: number;
    endLineNumber: number;
    endColumn: number;
  };
  matches: Array<string> | null;
};

type FindPatternProps = {
  pattern: string;
  highlightPattern?: boolean;
  onFindMatches?: (matches: Array<FindMatch>) => void;
};

type Props = {
  onChange: (value: Record<string, unknown>) => void;
  onValidation: (value: Record<string, unknown>) => void;
  type: 'css' | 'html' | 'all';
  height: string;
  theme: 'vs-light' | 'vs-dark';
  defaultValue: {
    css: string;
    html: string;
  };
  defaultValidtion: {
    css: Array<string>;
    html: Array<string>;
  };
  findPatternHtml?: FindPatternProps;
};

type TabScreens = Array<{
  id: number;
  name: string;
  content: JSX.Element;
}>;

export const CodeEditor = ({
  onChange,
  onValidation,
  defaultValue = { css: 'css', html: 'html' },
  defaultValidtion = { css: [], html: [] },
  type = 'all',
  height = '500px',
  theme = 'vs-light',
  findPatternHtml,
}: Props): JSX.Element => {
  const [codestate, setCodestate] = useState(defaultValue);
  const [validation, setValidation] = useState(defaultValidtion);
  const { css, html } = codestate;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const htmlEditorRef = useRef<any>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const monacoRef = useRef<any>(null);

  const findPattern = (findPatternProps: FindPatternProps) => {
    if (!htmlEditorRef.current || !monacoRef.current) {
      return;
    }

    const { pattern, highlightPattern, onFindMatches } = findPatternProps;
    const model = htmlEditorRef.current.getModel();
    const matches = model.findMatches(pattern, false, true, false, null, true);

    if (onFindMatches) onFindMatches(matches);

    if (highlightPattern) {
      model.deltaDecorations(
        [],
        matches.map(
          ({
            range: { startLineNumber, startColumn, endLineNumber, endColumn },
          }) => ({
            range: new monacoRef.current.Range(
              startLineNumber,
              startColumn,
              endLineNumber,
              endColumn
            ),
            options: {
              inlineClassName: 'highlighted-pattern',
              stickiness: TrackedRangeStickiness.NeverGrowsWhenTypingAtEdges,
            },
          })
        )
      );
    }
  };

  const handleHtmlEditorDidMount = (editor, monaco) => {
    htmlEditorRef.current = editor;
    monacoRef.current = monaco;

    if (findPatternHtml) {
      findPattern(findPatternHtml);
    }
  };

  useEffect(() => {
    if (findPatternHtml) {
      findPattern(findPatternHtml);
    }
  }, [codestate]);

  const handleCSSEditorValidation = (markers) => {
    const cssValidationArray = markers.map((marker) => marker.message);
    setValidation({
      ...validation,
      css: cssValidationArray,
    });
  };

  const handleHTMLEditorValidation = (markers) => {
    const htmlValidationArray = markers.map((marker) => marker.message);
    setValidation({
      ...validation,
      html: htmlValidationArray,
    });
  };

  const handleOnChangeHTML = (value) => {
    setCodestate({
      ...codestate,
      html: value,
    });
  };

  const handleOnChangeCSS = (value) => {
    setCodestate({
      ...codestate,
      css: value,
    });
  };

  const handleOnApply = (value) => () => {
    onValidation(validation);
    onChange(value);
  };

  const options = {
    selectOnLineNumbers: true,
    colorDecorators: true,
    roundedSelection: false,
    scrollBeyondLastLine: false,
    wordWrap: 'on',
    overviewRulerLanes: 0,
    lineDecorationsWidth: 8,
    lineNumbersMinChars: 4,
  };

  const tabScreens: TabScreens | never = [];

  const htmlScreen = {
    id: 0,
    name: 'HTML',
    content: (
      <Editor
        height={height}
        language='html'
        theme={theme}
        value={html}
        onChange={handleOnChangeHTML}
        options={options}
        loading='Loading...'
        onMount={handleHtmlEditorDidMount}
        onValidate={handleHTMLEditorValidation}
      />
    ),
  };

  const cssScreen = {
    id: 1,
    name: 'CSS',
    content: (
      <Editor
        height={height}
        language='scss'
        theme={theme}
        value={css}
        onChange={handleOnChangeCSS}
        options={options}
        loading='Loading...'
        onValidate={handleCSSEditorValidation}
      />
    ),
  };

  switch (type) {
    case 'css':
      tabScreens.push(cssScreen);
      break;
    case 'html':
      tabScreens.push(htmlScreen);
      break;
    case 'all':
      tabScreens.push(htmlScreen, cssScreen);
      break;
  }

  return (
    <CodeEditorWrapper theme={theme}>
      <TabComponent
        tabs={tabScreens}
        size='small'
        className='custom-code-editor-combined'
        actions={
          <div className='custom-code-tab-actions'>
            <Button
              variant='link'
              component='a'
              size='medium'
              text='Update'
              startIcon='Spinner'
              onClick={handleOnApply(codestate)}
            />
          </div>
        }
      />
    </CodeEditorWrapper>
  );
};
