import React, { useEffect } from 'react';
import {
  ContentPreviewModal,
  ContentPreviewModalProps,
} from '../ContentPreviewModal';
import { Icon } from '../../Atoms/Icon/Icon';
import * as S from './ContentPreviewButtons.styles';

export type ContentPreviewButtonsProps = {
  contentMode: ContentPreviewModalProps['contentMode'];
  mobileContent: ContentPreviewModalProps['mobileContent'];
  desktopContent: ContentPreviewModalProps['desktopContent'];
  subject?: ContentPreviewModalProps['subject'];
  mobileWidth?: ContentPreviewModalProps['mobileWidth'];
  desktopWidth?: ContentPreviewModalProps['desktopWidth'];
  onButtonClicked?: (
    previewMode: ContentPreviewModalProps['initialPreviewState']
  ) => void;
  onTabClicked?: (
    previewMode: ContentPreviewModalProps['initialPreviewState']
  ) => void;
  onExitButtonClicked?: () => void;
};

export const ContentPreviewButtons = (props: ContentPreviewButtonsProps) => {
  const {
    contentMode,
    mobileContent,
    desktopContent,
    subject,
    mobileWidth,
    desktopWidth,
    onTabClicked,
    onButtonClicked = () => {},
    onExitButtonClicked = () => {},
  } = props;

  const [openState, setOpenState] = React.useState<
    ContentPreviewModalProps['initialPreviewState']
  >();

  return (
    <>
      <S.ButtonWrapper>
        <S.Button
          onClick={() => {
            setOpenState('desktop');
            onButtonClicked('desktop');
          }}
          aria-label='desktop preview button'
        >
          <Icon icon='Desktop' />
        </S.Button>
        <S.Button
          onClick={() => {
            setOpenState('mobile');
            onButtonClicked('mobile');
          }}
          aria-label='mobile preview button'
        >
          <Icon icon='Mobile' />
        </S.Button>
      </S.ButtonWrapper>
      <ContentPreviewModal
        handleClose={() => {
          setOpenState(undefined);
          onExitButtonClicked();
        }}
        initialPreviewState={openState}
        isOpen={!!openState}
        mobileContent={mobileContent}
        desktopContent={desktopContent}
        contentMode={contentMode}
        subject={subject}
        mobileWidth={mobileWidth}
        desktopWidth={desktopWidth}
        onTabClicked={onTabClicked}
      />
    </>
  );
};
