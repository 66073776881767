import { Box } from '@material-ui/core';
import React from 'react';
import { Icon } from '../../Atoms/Icon';
import { StyledMenuNavigation } from './MenuNavigation.styles';
import { SideNavigation, SideNavigationProps } from './SideNavigation';
import { TopNavBar } from './TopNavBar';
import { TopNavBarProps } from './TopNavBar/TopNavBar.types';

export const SIDE_NAVIGATION_ITEMS = {
  Contacts: [
    {
      label: 'Contacts',
      count: '1000+',
    },
    {
      label: 'Lists',
      count: 3,
    },
  ],
  Campaigns: [
    {
      label: 'Email',
      count: 80,
    },
    {
      label: 'SMS',
      count: 80,
    },
    {
      label: 'WhatsApp',
      count: 80,
    },
    {
      label: 'Multi-Channels',
      count: 80,
    },
    {
      label: (
        <Box display='flex' alignItems='center'>
          <Icon icon='Add' style={{ marginRight: '15px' }} />
          Channels
        </Box>
      ),
    },
  ],
  Conversations: [
    {
      label: 'Home',
    },
    {
      label: 'Inbox',
      count: '100+',
    },
    {
      label: 'Tasks',
    },
    {
      label: 'Contacts',
      count: '100+',
    },
    {
      label: 'Settings',
      children: [
        {
          label: 'General',
        },
        {
          label: 'Channels',
        },
      ],
    },
  ],
  Workflows: [
    {
      label: 'Workflows',
      count: 25,
      children: [
        {
          label: 'Active',
        },
        {
          label: 'Drafts',
        },
        {
          label: 'Archived',
        },
      ],
    },
    {
      label: 'Templates',
    },
  ],
  Analytics: [
    {
      label: 'Analytics',
    },
    {
      label: 'Engagement',
      children: [
        {
          label: 'Click performance',
        },
      ],
    },
    {
      label: 'Recipient',
    },
    {
      label: 'Forms Insight',
    },
  ],
};
export type MenuNavigationProps = SideNavigationProps &
  TopNavBarProps & {
    children?: React.ReactElement;
    hasSideNavigation?: boolean;
  };

export const MenuNavigation = ({
  navLinks,
  actions,
  customActions,
  currentPage,
  sideNavItems,
  isDefaultExpanded,
  onClickLink,
  onClickLogo,
  children,
  hasSideNavigation = true,
}: MenuNavigationProps) => {
  return (
    <StyledMenuNavigation maxWidth={false}>
      <TopNavBar
        onClickLogo={onClickLogo}
        onClickLink={onClickLink}
        navLinks={navLinks}
        actions={actions}
        customActions={customActions}
        currentPage={currentPage}
      />
      <Box display='flex'>
        {hasSideNavigation && (
          <SideNavigation
            currentPage={currentPage}
            sideNavItems={sideNavItems}
            isDefaultExpanded={isDefaultExpanded}
          />
        )}
        <main className='navigation-content'>{children}</main>
      </Box>
    </StyledMenuNavigation>
  );
};
