import React, { useState } from 'react';
import { Button } from '../../Atoms/Navigations/Button';
import { ListFormModal } from '../ListFormModal/ListFormModal';
import { FormDataType } from '../ListForm/ListForm.types';
import { COMPANY_INFO_FORM_SECTIONS } from './FORM_SECTIONS';

const LABEL = 'Edit Company Info';

export const EditCompanyInfoButton = ({
  handleSave,
  defaultData,
}: {
  handleSave: (data: FormDataType) => void;
  defaultData: FormDataType;
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const handleExit = () => setModalOpen(false);

  return (
    <>
      <Button
        type='secondary'
        text={LABEL}
        size='medium'
        onClick={() => setModalOpen(true)}
      />
      <ListFormModal
        title={LABEL}
        formSections={COMPANY_INFO_FORM_SECTIONS}
        defaultData={defaultData}
        isOpen={modalOpen}
        handleExit={handleExit}
        handleSave={handleSave}
        alertContent='unsubscribe'
      />
    </>
  );
};
