import React, { useState } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { Icons } from '../../Foundation/Icons';
import {
  TabPanelTypes,
  a11yPropsTypes,
  TabComponentTypes,
} from './TabComponent.types';
import { TabsContainer } from './TabComponent.styles';

const TabPanel = ({ children, value, index }: TabPanelTypes): JSX.Element => {
  return (
    <Typography
      component='div'
      role='tabpanel'
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
    >
      {children}
    </Typography>
  );
};

const a11yProps = ({ index }: a11yPropsTypes) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

export const TabComponent = ({
  size,
  tabs,
  defaultActiveTabId = 0,
  onTabsChange,
  className = '',
  actions,
  centered,
}: TabComponentTypes): JSX.Element => {
  const [activeTab, setActiveTab] = useState(defaultActiveTabId);

  const handleOnChange = (
    event: React.ChangeEvent<{ innerText?: string }>,
    activeTabId: number
  ) => {
    if (event?.target?.innerText) {
      const activeTabName = event.target.innerText.trim();
      setActiveTab(activeTabId);
      return onTabsChange && onTabsChange(activeTabName, activeTabId);
    }
  };

  return (
    <TabsContainer size={size} tabs={tabs} className={className}>
      <Tabs
        value={activeTab}
        classes={{
          indicator: 'tabs-indicator',
        }}
        onChange={(event, activeTab) => handleOnChange(event, activeTab)}
        centered={centered}
      >
        {tabs.map((item) => {
          const { id, name, icon } = item;
          const IconName = icon ? Icons[icon] : null;
          const label = icon ? (
            <div>
              <IconName /> {name}
            </div>
          ) : (
            name
          );

          return (
            <Tab
              key={id}
              classes={{ root: '', selected: 'is-active' }}
              label={label}
              {...a11yProps({ index: id })}
            />
          );
        })}
        {actions && (
          <>
            <div className='tabs-action-pusher'></div>
            {actions}
          </>
        )}
      </Tabs>
      {tabs.map((item, index) => {
        const { id, content } = item;
        return (
          <TabPanel key={id} value={activeTab} index={index}>
            {content}
          </TabPanel>
        );
      })}
    </TabsContainer>
  );
};
