import { isValidPhoneNumber } from 'libphonenumber-js';
import * as yup from 'yup';
import {
  GenericFormFieldAttributes,
  FormSections,
  isValidField,
} from '../ListForm.types';

const getSchemaValidationFromFieldType = <TFormDataType>(
  field: GenericFormFieldAttributes<TFormDataType>
) => {
  const {
    // fieldComponent,
    label,
    // attribute,
    // description,
    format,
    required,
  } = field;

  let fieldSchemaValidation;

  switch (format) {
    case 'email':
      fieldSchemaValidation = yup.string().email('Please enter a valid email');
      break;
    case 'phone':
      fieldSchemaValidation = yup
        .string()
        .test(
          'is-valid-phone-number',
          'Please enter a valid phone number',
          (value) => {
            return value ? isValidPhoneNumber(value) : true; // undefined/null values are valid
          }
        )
        .nullable(true);
      break;
    case 'url':
      fieldSchemaValidation = yup.string().url('Please enter a valid url');
      break;
    default:
      // undefined, no format specified
      fieldSchemaValidation = yup.string();
  }

  if (required) return fieldSchemaValidation.required(`${label} is required`);
  return fieldSchemaValidation;
};

export const generateFormSchema = <TFormDataType>(
  formSections: FormSections<TFormDataType>
) => {
  const schema = {} as Record<keyof TFormDataType, yup.AnySchema>;
  Object.values(formSections).forEach((section) => {
    section.forEach((fields) => {
      Object.values(fields).forEach((field) => {
        if (field && isValidField<TFormDataType>(field))
          schema[field.attribute] = getSchemaValidationFromFieldType(field);
      });
    });
  });

  return yup.object<Record<keyof TFormDataType, yup.AnySchema>>().shape(schema);
};
