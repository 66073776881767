import React from 'react';
import { IconTypes } from '../../../Atoms/Icon/Icon';

export interface TopNavBarHeaderContainerProps {
  isActive: boolean;
}

export type Page = {
  title: string;
  pathname: string;
  icon: IconTypes;
};

export const SPARX_PAGES: Array<Page> = [
  {
    title: 'Contacts',
    pathname: '/contacts',
    icon: 'ContactsOutline',
  },
  {
    title: 'Campaigns',
    pathname: '/campaigns',
    icon: 'MessagesOutline',
  },
  {
    title: 'Conversations',
    pathname: '/conversations',
    icon: 'Conversations',
  },
  {
    title: 'Workflows',
    pathname: '/workflows',
    icon: 'WorkflowOutline',
  },
  {
    title: 'Analytics',
    pathname: '/analytics',
    icon: 'AnalyticsOutline',
  },
];

export type ActionIcon = {
  icon: IconTypes;
  onClick?: () => void;
};

export interface TopNavBarProps {
  onClickLogo?: () => void;
  navLinks?: typeof SPARX_PAGES;
  actions?: Array<ActionIcon>;
  customActions?: React.ReactElement;
  onClickLink?: (page: Page) => void;
  currentPage: Page;
}
