import React from 'react';
import { Modal } from '../../Atoms/Dialogs/Modal';
import { ModalHeader } from '../../Molecules/ModalHeader/ModalHeader';
import { MobilePreview } from '../../Molecules/MobilePreview/MobilePreview';
import { DesktopPreview } from '../../Molecules/DesktopPreview/DesktopPreview';
import { TabComponent } from '../../Molecules/TabComponent/TabComponent';
import { Colors } from '../../Foundation/Colors/Colors';
import * as S from './ContentPreviewModal.styles';

// Size of the MobileCase SVG
const MOBILE_WIDTH = 344;
const MOBILE_HEIGHT = 674;

export type PreviewModes = 'desktop' | 'mobile';

export type ContentPreviewModalProps = {
  contentMode?: 'web' | 'email';
  mobileContent: React.ReactNode;
  desktopContent: React.ReactNode;
  subject?: string;
  initialPreviewState?: PreviewModes;
  isOpen: boolean;
  mobileWidth?: number;
  desktopWidth?: number;
  handleClose: () => void;
  onTabClicked?: (previewMode: PreviewModes) => void;
};

export const ContentPreviewModal = (props: ContentPreviewModalProps) => {
  const {
    contentMode,
    initialPreviewState = 'desktop',
    isOpen,
    subject,
    mobileContent,
    desktopContent,
    handleClose,
    mobileWidth,
    desktopWidth,
    onTabClicked = () => {},
  } = props;

  // align the max height of the desktop and mobile tabs
  const heightToUse = mobileWidth
    ? (mobileWidth * MOBILE_HEIGHT) / MOBILE_WIDTH
    : MOBILE_HEIGHT;

  return (
    <Modal
      isOpen={isOpen}
      isFullScreen
      headerContent={
        <ModalHeader
          title=''
          onCloseClick={handleClose}
          closeButtonText='Exit preview mode'
          showLogo={false}
        />
      }
      bodyContent={
        <S.TabWrapper height={heightToUse}>
          <TabComponent
            onTabsChange={(activeTabName) => {
              onTabClicked(activeTabName.toLowerCase() as PreviewModes);
            }}
            size='small'
            centered
            defaultActiveTabId={initialPreviewState === 'desktop' ? 0 : 1}
            tabs={[
              {
                id: 0,
                name: 'Desktop',
                icon: 'Desktop',
                content: (
                  <S.DesktopPreviewWrapper height={heightToUse}>
                    <DesktopPreview
                      desktopWidth={desktopWidth}
                      content={desktopContent}
                      subject={contentMode === 'email' ? subject : undefined}
                    />
                  </S.DesktopPreviewWrapper>
                ),
              },
              {
                id: 1,
                name: 'Mobile',
                icon: 'Mobile',
                content: (
                  <S.MobilePreviewWrapper>
                    <MobilePreview
                      useHTMLMobileCase
                      type='web'
                      message={mobileContent}
                      subject={contentMode === 'email' ? subject : undefined}
                      width={mobileWidth || MOBILE_WIDTH}
                      mobileCaseBackgroundColor={Colors.white}
                    />
                  </S.MobilePreviewWrapper>
                ),
              },
            ]}
          />
        </S.TabWrapper>
      }
      handleExit={handleClose}
      hideCloseButton
    />
  );
};
