import { Box, Divider, List, ListItem, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Page } from '../TopNavBar/TopNavBar.types';
import { Icon } from '../../../Atoms/Icon';
import { StyledCollapser, StyledDrawer } from './SideNavigation.styles';

type SideNavItem = {
  label: string | React.ReactElement;
  count?: string | number;
  pathname?: string;
  children?: Array<SideNavItem>;
  onClick?: () => void;
};

export type SideNavigationProps = {
  currentPage: Page;
  sideNavItems: Array<SideNavItem>;
  isDefaultExpanded?: boolean;
};

export const SideNavigation = ({
  currentPage,
  sideNavItems,
  isDefaultExpanded = true,
}: SideNavigationProps) => {
  const [open, setOpen] = useState(isDefaultExpanded);
  const [selected, setSelectedItem] = useState<{
    primary: number;
    secondary: number | null;
  }>({
    primary: 0,
    secondary: null,
  });
  const isSelectionBasedOnHistory = sideNavItems.every(
    ({ pathname }) => !!pathname
  );

  const history = useHistory();

  const handleToggleSideNav = (isOpen) => {
    setOpen(isOpen);
  };

  return (
    <>
      <StyledCollapser
        open={open}
        role='button'
        aria-label='side navigation toggle'
      >
        <Icon
          size='small'
          icon='DropupArrow'
          onClick={() => handleToggleSideNav(!open)}
        />
      </StyledCollapser>
      <StyledDrawer
        transitionDuration={200}
        anchor='left'
        open={open}
        onClose={() => handleToggleSideNav(false)}
        variant='permanent'
      >
        <Box display='flex' className='navigation-page-info'>
          <Box className='icon-wrapper'>
            <Icon icon={currentPage.icon} />
          </Box>
          <Typography>{currentPage.title}</Typography>
        </Box>
        <Divider />
        <List component='nav'>
          {sideNavItems.map((item, index) => (
            <>
              <ListItem
                button
                key={
                  typeof item.label === 'string'
                    ? (item.label as string)
                    : index
                }
                className='side-nav-items'
                selected={
                  isSelectionBasedOnHistory
                    ? sideNavItems[index].pathname === history.location.pathname
                    : selected.primary === index
                }
                onClick={() => {
                  setSelectedItem(() => {
                    const { onClick = () => {} } = sideNavItems[index];
                    onClick();

                    return {
                      primary: index,
                      secondary: null,
                    };
                  });
                }}
              >
                <Box display='flex' flexGrow={1}>
                  <Box flexGrow={1}>{item.label}</Box>
                  {item.count}
                </Box>
              </ListItem>
              {!!item.children && (
                <List component='div' className='side-nav-nested-items'>
                  {item.children?.map((child, childIndex) => (
                    <ListItem
                      button
                      key={
                        typeof child.label === 'string'
                          ? (child.label as string)
                          : childIndex
                      }
                      selected={selected.secondary === childIndex}
                      onClick={() =>
                        setSelectedItem(() => {
                          const { children = [] } = sideNavItems[index];
                          const { onClick = () => {} } = children[childIndex];
                          onClick();

                          return {
                            primary: index,
                            secondary: childIndex,
                          };
                        })
                      }
                    >
                      {child.label}
                    </ListItem>
                  ))}
                </List>
              )}
            </>
          ))}
        </List>
      </StyledDrawer>
    </>
  );
};
