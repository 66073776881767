import React from 'react';
import * as S from './Subject.styles';

export type SubjectProps = { subject: string };
export const SUBJECT_HEIGHT = 48;

export const Subject = (props: SubjectProps) => {
  const { subject } = props;
  return (
    <S.SubjectWrapper height={SUBJECT_HEIGHT}>
      <S.SubjectLine>Subject:&nbsp;</S.SubjectLine>
      {subject}
    </S.SubjectWrapper>
  );
};
