import { FieldErrors, Path, UseFormRegister } from 'react-hook-form';
import React from 'react';
import { GenericFormFieldAttributes } from '../../ListForm.types';
import { GenericTextField } from '../GenericTextField';

type SingleLineTextFieldProps<TFormDataType> = {
  register: UseFormRegister<TFormDataType>;
  errors: FieldErrors<TFormDataType>;
  name: Path<TFormDataType>;
} & Omit<GenericFormFieldAttributes<TFormDataType>, 'fieldComponent'>;

export const SingleLineTextField = <TFormDataType,>({
  errors,
  register,
  name,
  ...rest
}: SingleLineTextFieldProps<TFormDataType>) => (
  <GenericTextField errors={errors} register={register} name={name} {...rest} />
);
