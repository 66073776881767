import styled, { css } from 'styled-components';
import { Colors } from '../../Foundation/Colors';
import { Elevations } from '../../Foundation/Elevations';
import { Fonts } from '../../Foundation/Fonts';

export const MobilePreviewWrapper = styled.div<{
  height: number;
  width: number;
  mobileCaseBackgroundColor?: string;
}>`
  position: relative;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;

  .mobile-case {
    position: absolute;
    z-index: 0;

    ${({ mobileCaseBackgroundColor }) =>
      mobileCaseBackgroundColor &&
      css`
        path {
          fill: ${mobileCaseBackgroundColor};
        }
        svg {
          path {
            fill: ${mobileCaseBackgroundColor};
          }
        }
      `}
  }

  .scroll-wrapper {
    position: absolute;
    margin: 40px 0 24px 45px;
    top: 50px;
    height: 526px;
    width: 260px;
    overflow-y: auto;

    .text-bubble {
      margin: 0;
    }

    &.whatsapp > .text-bubble {
      width: 200px;
      border-radius: 5px;
      box-shadow: ${Elevations.elevation_1};
      background-color: ${Colors.white};

      &:after {
        border-right: 12px solid ${Colors.white};
      }
    }

    &.web {
      margin: 14px 0 16px 28px;
      height: 584px;
      width: 288px;
      overflow: hidden;
      border-radius: 12px 12px 42px 42px;

      .web-content {
        height: 100%;
        overflow-y: auto;
      }
    }
  }
  .text-bubble {
    position: relative;
    ${Fonts.body};
    line-height: 1.4;
    font-size: 13px;
    // TODO: make this width dynamic with the device width
    width: 175px;
    border-radius: 15px;
    padding: 12px;
    background-color: ${Colors.grey_blue_1};
    z-index: 1;
    margin: 16px;

    :after {
      content: '';
      position: absolute;
      bottom: -4px;
      left: -2px;
      border-top: 6px solid transparent;
      border-bottom: 3px solid transparent;
      border-right: 12px solid ${Colors.grey_blue_1};
      transform: rotate(-38deg);
    }
  }
`;
