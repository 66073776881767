import React from 'react';
import { Control, FieldErrors, Path, UseFormRegister } from 'react-hook-form';
import {
  AllFormFieldsWithAttributes,
  FieldComponent,
  GenericFormFieldAttributes,
} from '../ListForm.types';
import { PhoneNumberField } from '../ListFormFieldComponents/PhoneNumberField/PhoneNumberField';
import { SingleLineTextField } from '../ListFormFieldComponents/SingleLineTextField/SingleLineTextField';

export const getFormField = <TFormDataType,>(props: {
  formField: GenericFormFieldAttributes<TFormDataType>;
  register: UseFormRegister<TFormDataType>;
  errors: FieldErrors<TFormDataType>;
  name: Path<TFormDataType>;
  control: Control<TFormDataType>;
}) => {
  const { formField, control, register, ...rest } = props;
  const { fieldComponent, ...fieldProps } = formField;
  switch (formField.fieldComponent) {
    case FieldComponent.SingleLineText:
      return (
        <SingleLineTextField<TFormDataType>
          register={register}
          {...rest}
          {...fieldProps}
        />
      );
    case FieldComponent.Phone:
      return (
        <PhoneNumberField<TFormDataType>
          control={control}
          {...rest}
          {...fieldProps}
        />
      );
    default:
      return null;
  }
};

export const generateFormFields = <TFormDataType,>(props: {
  fields: Array<AllFormFieldsWithAttributes<TFormDataType>>;
  register: UseFormRegister<TFormDataType>;
  errors: FieldErrors<TFormDataType>;
  control: Control<TFormDataType>;
}) => {
  const { fields, ...formFieldProps } = props;
  return fields.map((field) => {
    return Object.entries<GenericFormFieldAttributes<TFormDataType>>(field).map(
      ([key, value]) => {
        return getFormField({
          formField: value,
          name: key as Path<TFormDataType>,
          ...formFieldProps,
        });
      }
    );
  });
};
