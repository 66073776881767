import { Path, UseFormProps } from 'react-hook-form';
import { AnyObjectSchema } from 'yup';

// default/generic form type
export type FormDataType = {
  [key: string]: unknown;
};

export type ListFormProps<TFormDataType> = {
  formSections: FormSections<TFormDataType>;
  onSubmit: (data: TFormDataType) => void;
  onCancel: () => void;
  // it sucks having to deep dive documentation to find out stuff like these default values,
  // given all the extra work and that the validators kind of take care of type
  // checking for us, we might not want to worry about typing ListForm very strictly.
  values?: TFormDataType;
  // you can set the default country code for phone inputs by setting the value for phone to the international number prefix
  formOptions?: FormOptions;
};

type FormOptions = Partial<{
  overrideYupValidation: AnyObjectSchema;
}>;

export type FormSections<TFormDataType = FormDataType> = Record<
  string,
  // A slight issue with this typing is it doesn't require all fields in the type passed in
  // to be defined in this FormSections type, due to the use of Partial below. Otherwise, however, it would
  // require all fields to be defined in every section.
  // I.e. it will show an error if there are any fields within a section that are not
  // defined in the type passed in, but will not show an error if there are fields missing or duplicated.
  Array<Partial<AllFormFieldsWithAttributes<TFormDataType>>>
>;

export type AllFormFieldsWithAttributes<TFormDataSubType> = Record<
  Path<TFormDataSubType>,
  GenericFormFieldAttributes<TFormDataSubType>
>;

export enum FieldComponent {
  SingleLineText = 'single_line_text',
  Phone = 'phone',
}

type FormFieldFormats = 'email' | 'url' | 'phone' | 'date' | 'time';

type FormFieldAttributes = {
  fieldComponent: FieldComponent; // which field component to use
  label: string;
  description: string; // used for aria-describedby and maybe helperText?
  // customerId: string; // might be necessary
  format?: FormFieldFormats; // used for validation
  required?: boolean;
  attribute: string; // used for aria-labelledby
};

export type GenericFormFieldAttributes<TFormDataSubType> = Omit<
  FormFieldAttributes,
  'attribute'
> & {
  attribute: keyof TFormDataSubType;
};

export function isValidField<TFormDataType>(
  field: unknown | undefined
): field is GenericFormFieldAttributes<TFormDataType> {
  if (field) {
    const typdeField = field as GenericFormFieldAttributes<TFormDataType>;
    return (
      typdeField.fieldComponent !== undefined &&
      typdeField.label !== undefined &&
      typdeField.attribute !== undefined &&
      typdeField.description !== undefined
    );
  }
  return false;
}
