import { FieldComponent, FormSections } from '../ListForm/ListForm.types';

export const companyInfoDefaultValues = {
  name: '',
  organisation: '',
  email: '',
  phone: '',
  'address-line-1': '',
  'address-line-2': '',
  city: '',
  state: '',
  'post-code': '',
  country: '',
  'website-url': '',
};

export const COMPANY_INFO_FORM_SECTIONS: FormSections<
  typeof companyInfoDefaultValues
> = {
  'Contact Information': [
    {
      name: {
        fieldComponent: FieldComponent.SingleLineText,
        attribute: 'name',
        required: true,
        label: 'Name',
        description: '',
      },
    },
    {
      organisation: {
        fieldComponent: FieldComponent.SingleLineText,
        attribute: 'organisation',
        required: true,
        label: 'Organisation',
        description: '',
      },
    },
    {
      email: {
        fieldComponent: FieldComponent.SingleLineText,
        attribute: 'email',
        required: false,
        label: 'Email',
        description: '',
        format: 'email',
      },
    },
    {
      phone: {
        fieldComponent: FieldComponent.Phone,
        attribute: 'phone',
        required: false,
        label: 'Mobile Phone',
        description: '',
        format: 'phone',
      },
    },
  ],
  Address: [
    {
      'address-line-1': {
        fieldComponent: FieldComponent.SingleLineText,
        attribute: 'address-line-1',
        label: 'Address 1',
        description: '',
      },
    },
    {
      'address-line-2': {
        fieldComponent: FieldComponent.SingleLineText,
        attribute: 'address-line-2',
        label: 'Address 2',
        description: '',
      },
    },
    {
      city: {
        fieldComponent: FieldComponent.SingleLineText,
        attribute: 'city',
        label: 'City',
        description: '',
      },
    },
    {
      state: {
        fieldComponent: FieldComponent.SingleLineText,
        attribute: 'state',
        label: 'State',
        description: '',
      },
    },
    {
      'post-code': {
        fieldComponent: FieldComponent.SingleLineText,
        attribute: 'post-code',
        label: 'Postal code',
        description: '',
      },
    },
    {
      country: {
        fieldComponent: FieldComponent.SingleLineText,
        attribute: 'country',
        label: 'Country',
        description: '',
      },
    },
  ],
  'Aditional Details': [
    {
      'website-url': {
        fieldComponent: FieldComponent.SingleLineText,
        attribute: 'website-url',
        label: 'Website URL',
        description: '',
        format: 'url',
      },
    },
  ],
};
