import React from 'react';
import { ListForm } from '../ListForm/ListForm';
import { FormDataType, FormSections } from '../ListForm';
import { Modal } from '../../Atoms/Dialogs/Modal';
import * as S from './ListFormModal.styles';

export type ListFormModalProps = {
  isOpen: boolean;
  handleExit: () => void;
  handleSave: (data: FormDataType) => void;
  defaultData: FormDataType;
  title: string;
  formSections: FormSections;
  alertContent?: 'unsubscribe' | string | JSX.Element;
};

const getAlertContent = (alertContent: ListFormModalProps['alertContent']) => (
  <S.AlertContent>
    {alertContent === 'unsubscribe' ? (
      <>
        <span>{`Important: `}</span>
        {`To meet international spam laws, all marketing
    emails must include your physical mailing address and contact information.
    We'll automatically place this information in the footer of your emails.`}
        <br />
        <br />
        {`We'll also use this information if someone reports your campaign as spam to
    our `}
        <a href='https://www.whispir.com/en-au/'>Help Desk.</a>
        <br />
        <br />
        <a href='https://www.whispir.com/en-au/'>Learn more</a>
        {` about the terms of
    use and rules for campaigns.`}
      </>
    ) : (
      alertContent
    )}
  </S.AlertContent>
);

export const ListFormModal = (props: ListFormModalProps) => {
  const {
    handleExit,
    handleSave,
    isOpen,
    defaultData = {} as FormDataType,
    title,
    formSections,
    alertContent,
  } = props;

  return (
    <Modal
      handleExit={handleExit}
      isOpen={isOpen}
      headerContent={<S.Header>{title}</S.Header>}
      width='624px'
      size='standard'
      bodyContent={
        <>
          {alertContent && (
            <S.StyledAlert
              type='inline'
              alert='info'
              content={getAlertContent(alertContent)}
            />
          )}
          <ListForm<typeof defaultData>
            formSections={formSections}
            onSubmit={(data) => {
              handleSave(data);
              handleExit();
            }}
            onCancel={handleExit}
            values={defaultData}
          />
        </>
      }
    />
  );
};
