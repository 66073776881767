import styled from 'styled-components';
import { Colors } from '../../../Foundation/Colors';
import { Fonts } from '../../../Foundation/Fonts';

const tabWidth = '128px';
export const navBarHeight = 80;

export const StyledTopNavBarContainer = styled.div`
  height: ${navBarHeight};

  .MuiAppBar-colorPrimary {
    background-color: ${Colors.background_dark_01};
  }

  .logo-container {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  svg {
    fill: ${Colors.white};
  }

  .futoro-logo {
    margin-right: 16px;
  }

  .whispir-logo {
    margin-right: 48px;

    svg {
      height: ${navBarHeight}px;
      width: auto;
    }
  }

  .MuiTab-root {
    text-transform: capitalize;
  }

  .MuiTab-root,
  .MuiTabs-fixed,
  .MuiTabs-flexContainer {
    height: ${navBarHeight}px;
  }

  .MuiButtonBase-root.MuiTab-root {
    ${Fonts.link}

    min-width: ${tabWidth};
    box-sizing: border-box;

    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }

    &.Mui-selected {
      background: rgba(255, 255, 255, 0.15);
    }
  }

  .MuiTabs-indicator {
    height: 5px;
    width: ${tabWidth};
    background-color: ${Colors.white};
  }
`;
