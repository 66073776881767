import styled from 'styled-components';
import { Fonts } from '../../Foundation/Fonts';
import { Colors } from '../../Foundation/Colors';
import { Alert } from '../../Molecules/Alert';

export const BodyContentWrapper = styled.div`
  overflow: hidden;
`;

export const Header = styled.div`
  ${Fonts.headline}
  padding-bottom: 24px;
`;

export const AlertContent = styled.div`
  ${Fonts.body}
  margin-right: 30px;

  span,
  a {
    // in the designs this is Fonts.link but that has a different line height,
    // so doesn't fit with the other text - only other difference is the weight
    font-weight: 500;
    text-decoration: none;
  }

  a {
    color: ${Colors.accent};
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const StyledAlert = styled(Alert)`
  & {
    padding: 0 24px;
  }
`;
