import Dialog from '@material-ui/core/Dialog';

import styled from 'styled-components';

export const StyledDialog = styled(Dialog)<{
  width: string;
  fullScreen?: boolean;
}>`
  .MuiDialog-paper {
    padding: ${({ fullScreen }) => (fullScreen ? '0' : '32px')};
    border-radius: 8px;
    box-sizing: border-box;
  }

  & .modal-header {
    display: flex;
  }

  & .header-wrapper {
    width: stretch;
    align-self: center;
  }

  & .exit-button-wrapper {
    align-self: flex-start;
  }

  &.size-small {
    .MuiDialog-paper:not(.MuiDialog-paperFullScreen) {
      width: 480px;
    }
  }

  &.size-extra-small {
    .MuiDialog-paper:not(.MuiDialog-paperFullScreen) {
      width: 416px;
    }

    & .exit-button-wrapper {
      align-self: flex-start;
      margin-top: -19px;
      margin-right: -19px;
    }

    & .header-wrapper {
      svg {
        margin-right: -24px;
        padding-top: 24px;
      }
    }
  }

  &.size-standard {
    .MuiDialog-paper:not(.MuiDialog-paperFullScreen) {
      width: ${({ width }) => width};
      max-width: ${({ width }) => width};
    }
  }

  .MuiDialog-paperFullScreen {
    height: 100%;
    box-sizing: border-box;
    max-width: 100%;
    border-radius: 0;
  }
`;
