import React from 'react';
import { DefaultValues, useForm } from 'react-hook-form';
import { MuiThemeProvider } from '@material-ui/core';
import { yupResolver } from '@hookform/resolvers/yup';
import { generateFormFields } from './utils/formUtils';
import {
  AllFormFieldsWithAttributes,
  FormDataType,
  ListFormProps,
} from './ListForm.types';
import * as S from './ListForm.styles';
import { generateFormSchema } from './utils/validation';

export const ListForm = <TFormDataType extends FormDataType = FormDataType>(
  props: ListFormProps<TFormDataType>
) => {
  const { onSubmit, onCancel, values, formSections, formOptions } = props;

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<TFormDataType>({
    mode: 'onChange',
    // See for why we need to cast as DefaultValues:
    // https://github.com/react-hook-form/react-hook-form/discussions/3713
    defaultValues: values as DefaultValues<TFormDataType>,
    resolver: yupResolver(
      formOptions?.overrideYupValidation ||
        // getting type error, I think due to type mismatch between yup and @hookform/resolvers/yup
        // generateFormSchema<TFormDataType>(formSections) as yup.AnyObjectSchema
        (generateFormSchema<TFormDataType>(formSections) as any)
    ),
  });

  return (
    <MuiThemeProvider theme={S.formLabelsTheme}>
      {/* This issue needs to be reopened: https://github.com/react-hook-form/react-hook-form/issues/2978 */}
      {/* @ts-ignore */}
      <form data-testid='form' onSubmit={handleSubmit(onSubmit)}>
        {Object.entries(formSections).map(([keyLabel, fields]) => {
          const typedFields = fields as Array<
            AllFormFieldsWithAttributes<TFormDataType>
          >;
          return (
            // TODO: getting a duplicate keys warning around here
            <React.Fragment key={keyLabel}>
              <S.SubHeading key={`${keyLabel}-subheading`}>
                {keyLabel}
              </S.SubHeading>
              <S.FormSection key={`${keyLabel}-section`}>
                {generateFormFields<TFormDataType>({
                  fields: typedFields,
                  register,
                  errors,
                  control,
                })}
              </S.FormSection>
            </React.Fragment>
          );
        })}
        <S.ButtonsWrapper>
          <S.StyledButton variant='outlined' onClick={onCancel}>
            Cancel
          </S.StyledButton>
          <S.StyledButton variant='contained' type='submit'>
            Save
          </S.StyledButton>
        </S.ButtonsWrapper>
      </form>
    </MuiThemeProvider>
  );
};
