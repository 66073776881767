import React, { useState, useEffect, useCallback } from 'react';
import {
  Table,
  TableBody,
  TablePagination,
  TableContainer,
} from '@material-ui/core';
import { typedMemo } from '../../Utils/typedMemo';
import { Spinner } from '../../Atoms/Indicators';
import { Illustrations } from '../../Foundation/Illustrations';
import { EmptyTemplateState } from '../../Molecules/EmptyTemplateState';
import { StyledTableWrapper } from '../DataTable/DataTable.styles';
import {
  QueryDataTableProps,
  SelectionState,
  RowData,
  SELECTION_STATE,
} from './QueryDataTable.types';
import { TableRow } from './TableRow';
import { EnhancedTableHead } from './TableHeader';

const DEFAULT_FIRST_SORT_ORDER = true; // First click of a column will sort ascending.
const defaultRowActions = [];
const defaultRowsPerPageOptions = [5, 10, 25, 50, 100];
const defaultEmptyTablePlaceholder = (
  <EmptyTemplateState
    illustration={<Illustrations.EmptyState />}
    title='No records found'
  />
);

export const QueryDataTable = typedMemo(
  <T extends RowData>(props: QueryDataTableProps<T>): JSX.Element => {
    const {
      rows,
      columns,
      isLoading,
      totalRowsInAllPages,
      selectMode = 'none',
      rowActions = defaultRowActions,
      batchActions = [],
      onTableViewChange = () => undefined,
      onSelectionChange = () => undefined,
      className,
      maxHeight,
      page = 0,
      rowsPerPage = 5,
      rowsPerPageOptions = defaultRowsPerPageOptions,
      emptyTablePlaceholder = defaultEmptyTablePlaceholder,
      isDisabledSort,
      defaultSortedColumn,
      defaultSortAscending = true,
      selectOnRowClick = true,
      onRowClick = () => undefined,
      disableRow,
    } = props;

    /**
     * STATES
     */
    // Keep track of pagination data
    const [currentPage, setCurrentPage] = useState(page);
    const [currentRowsPerPage, setCurrentRowsPerPage] = useState(rowsPerPage);

    // rows data
    // just to make sure we're getting row not exceeding the rows per page
    const [rowsData, setRowsData] = useState(rows);

    // Keep track of which rows are selected
    const [selectedRows, setSelectedRows] = useState<Array<string | number>>(
      []
    );

    // This basically just used to determine what the select all checkbox looks like
    const [selectionState, setSelectionState] = useState<SelectionState>(
      SELECTION_STATE.NONE
    );

    // Keep track of sort data
    const [columnSortKey, setColumnSortKey] = useState(
      defaultSortedColumn || columns[0].field
    );
    const [columnSortAscending, setColumnSortAscending] = useState(
      defaultSortAscending
    );

    /** SELECTION HANDLERS */
    const handleRowClick = useCallback(
      (row: T) => {
        const { id } = row;
        if (selectMode === 'none') {
          return;
        }

        if (selectMode === 'single') {
          onRowClick(row);
          setSelectedRows([id]);
          setSelectionState(SELECTION_STATE.SOME);
          return;
        }

        if (selectMode === 'multi') {
          const newSelectedRows = [...selectedRows];
          const selectedRowIdx = newSelectedRows.findIndex(
            (rowId) => rowId === id
          );
          if (selectedRowIdx > -1) {
            newSelectedRows.splice(selectedRowIdx, 1);
          } else {
            newSelectedRows.push(id);
          }
          setSelectedRows(newSelectedRows);

          if (newSelectedRows.length > 0)
            setSelectionState(SELECTION_STATE.SOME);
          else setSelectionState(SELECTION_STATE.NONE);
        }
      },
      [selectMode, selectedRows]
    );

    // handler for select all checkbox
    const handleSelectAllInCurrentPage = () => {
      if (SELECTION_STATE.NONE !== selectionState) {
        setSelectedRows([]);
        setSelectionState(SELECTION_STATE.NONE);
      } else {
        setSelectedRows(rowsData.map(({ id }) => id));
        setSelectionState(SELECTION_STATE.ALL);
      }
    };

    // handler for select all batch action, selectAllMode: true => select all, false => deselect all
    const handleBatchSelectAll = (selectAllMode: boolean) => {
      if (selectAllMode) {
        setSelectedRows(rowsData.map(({ id }) => id));
        setSelectionState(SELECTION_STATE.ALL_PAGES);
      } else {
        setSelectedRows([]);
        setSelectionState(SELECTION_STATE.NONE);
      }
    };

    useEffect(() => {
      onSelectionChange(selectionState, selectedRows);
    }, [selectedRows, selectionState]);

    /**
     * PAGINATION HANDLERS
     *
     */
    const handleChangePage = (_event, page) => {
      setCurrentPage(page);
    };

    const handleChangeRowsPerPage = ({ target: { value } }) => {
      setCurrentRowsPerPage(value);
      setCurrentPage(0);
    };

    const handleSortClick = (columnKey: string) => {
      let newSortDirection;

      // If we are already sorting this column, just invert the order
      if (columnKey === columnSortKey) {
        newSortDirection = !columnSortAscending;
      } else {
        // If it is a new key, sort using default order
        setColumnSortKey(columnKey);
        newSortDirection = DEFAULT_FIRST_SORT_ORDER;
      }

      setColumnSortAscending(newSortDirection);
    };

    useEffect(() => {
      setRowsData([...rows]);
    }, [rows]);

    useEffect(() => {
      setCurrentPage(page);
    }, [page]);

    /**
     * Every change of page, rows per page or sorting causes a change to data rows
     * and should call new Query in the consumer.
     */
    useEffect(() => {
      onTableViewChange({
        page: currentPage,
        rowsPerPage: currentRowsPerPage,
        columnToSort: !isDisabledSort ? columnSortKey : undefined,
        sortAscending: !isDisabledSort ? columnSortAscending : undefined,
      });
    }, [
      currentPage,
      currentRowsPerPage,
      columnSortKey,
      columnSortAscending,
      isDisabledSort,
    ]);

    const isRowSelected = (rowId: string | number) =>
      SELECTION_STATE.ALL === selectionState ||
      SELECTION_STATE.ALL_PAGES === selectionState ||
      selectedRows.includes(rowId);

    const hasRowsToDisplay = rowsData && rowsData.length > 0;
    return (
      <StyledTableWrapper maxHeight={maxHeight} className='data-table-wrapper'>
        <TableContainer className={`${className} select-mode-${selectMode}`}>
          <Table
            stickyHeader
            className={isLoading || !hasRowsToDisplay ? 'spinner-wrapper' : ''}
          >
            <EnhancedTableHead
              columns={columns}
              selectMode={selectMode}
              selectAllClick={handleBatchSelectAll}
              selectAllInPageClick={handleSelectAllInCurrentPage}
              selectionState={selectionState}
              onSortClick={handleSortClick}
              columnSortKey={columnSortKey}
              columnSortAscending={columnSortAscending}
              hasRowActionsColumn={rowActions.length > 0}
              batchActions={batchActions}
              selectedRows={selectedRows}
              totalNumberOfRows={totalRowsInAllPages || rows.length}
              isDisabledSort={isDisabledSort}
            />
            <TableBody>
              {isLoading ? (
                <Spinner />
              ) : hasRowsToDisplay ? (
                rowsData.map((rowData) => {
                  const isSelected = isRowSelected(rowData.id);
                  // selectively disabling rows by passing a function validator
                  const isRowDisabled = disableRow
                    ? disableRow(rowData)
                    : false;
                  return (
                    <TableRow
                      key={rowData.id}
                      rowData={rowData}
                      isSelected={isSelected}
                      selectMode={selectMode}
                      onRowClick={handleRowClick}
                      columns={columns}
                      rowActions={rowActions}
                      selectOnRowClick={selectOnRowClick}
                      isRowDisabled={isRowDisabled}
                    />
                  );
                })
              ) : (
                emptyTablePlaceholder
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          SelectProps={{ MenuProps: { disablePortal: true } }}
          component='div'
          page={currentPage}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          count={totalRowsInAllPages || rows.length}
          rowsPerPage={currentRowsPerPage}
          rowsPerPageOptions={rowsPerPageOptions}
          labelDisplayedRows={({ from, to, count }) =>
            `Displaying ${from}-${to} of ${count}`
          }
        />
      </StyledTableWrapper>
    );
  }
);
