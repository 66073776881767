import React from 'react';
import {
  ButtonProps as MUIButtonProps,
  ThemeProvider,
} from '@material-ui/core';
import { novaButtonTheme, StyledButton } from './NovaButton.styles';

export type NovaButtonProps = Omit<MUIButtonProps, 'children' | 'variant'> & {
  variant?: 'primary' | 'secondary';
} & ({ label: string } | { children: MUIButtonProps['children'] }); // This types allows the use of either label or children.

export const NovaButton = ({
  variant = 'primary',
  ...otherProps
}: NovaButtonProps) => {
  const muiVariant = 'primary' === variant ? 'contained' : 'outlined';

  return (
    <ThemeProvider theme={novaButtonTheme}>
      <StyledButton
        {...otherProps}
        color='primary'
        variant={muiVariant}
        disableRipple
      >
        {'label' in otherProps ? otherProps.label : otherProps.children}
      </StyledButton>
    </ThemeProvider>
  );
};
