import React from 'react';
import { Subject, SUBJECT_HEIGHT } from '../Subject/Subject';
import { DraftailPreview } from '../../DraftailComponents/DraftailPreview';
import MobileCase from '../../Assets/Svgs/MobileCase/MobileCase.svg';
import MobileCaseWhatsapp from '../../Assets/Svgs/MobileCase/MobileCaseWhatsapp.svg';
import { WebPreviewer } from '../../Atoms/WebPreviewer';
import { Colors } from '../../Foundation/Colors/Colors';
import { MobilePreviewTypes } from './MobilePreview.types';
import { MobilePreviewWrapper } from './MobilePreview.styles';

const CASE_WIDTH_OFFSET = 48;
const CASE_HEIGHT_OFFSET = 74;

const carriageReturnDisplay = (message: string) =>
  message.replace(/<\s*p[^>]*><\s*\/\s*p\s*>/g, '<p>&nbsp</p>');

const HTMLMobileCase = (props: {
  content: React.ReactNode;
  width: number;
  height: number;
  backgroundColor?: string;
  header?: React.ReactNode;
}) => {
  const {
    content,
    width,
    height,
    backgroundColor = Colors.grey_blue_0,
    header,
  } = props;
  const commonMobileStyles = {
    border: `1px solid ${Colors.grey_blue_1}`,
    borderRadius: '15% / 8%',
    positon: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  return (
    <div
      style={{
        width: width + CASE_WIDTH_OFFSET,
        height: height + CASE_HEIGHT_OFFSET,
        ...commonMobileStyles,
        borderColor: Colors.grey_blue_2,
      }}
    >
      <div
        style={{
          width: width + (CASE_WIDTH_OFFSET - 8),
          height: height + (CASE_HEIGHT_OFFSET - 8),
          ...commonMobileStyles,
        }}
      >
        <div
          style={{
            width: width + (CASE_WIDTH_OFFSET - 16),
            height: height + (CASE_HEIGHT_OFFSET - 16),
            ...commonMobileStyles,
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              position: 'relative',
              top: -12,
              right: -8,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                width: 44,
                height: 6,
                ...commonMobileStyles,
                borderRadius: 8,
              }}
            />
            <div
              style={{
                marginLeft: 6,
                width: 8,
                height: 8,
                ...commonMobileStyles,
                borderRadius: '50%',
              }}
            >
              <div
                style={{
                  width: 4,
                  height: 4,
                  ...commonMobileStyles,
                  borderRadius: '50%',
                }}
              />
            </div>
          </div>
          <div
            style={{
              width,
              height,
              backgroundColor,
              borderRadius: '5% 5% 10% 10% / 3% 3% 5% 5%',
              position: 'relative',
              bottom: -10,
              overflow: 'hidden',
              outline: `1px solid ${Colors.background}`,
            }}
          >
            {header}
            <WebPreviewer
              deviceHeight={header ? height - SUBJECT_HEIGHT : height}
              content={content}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export const MobilePreview = ({
  type = 'sms',
  message,
  media,
  width = 344, // Size of the MobileCase SVGs. When useHTMLMobileCase is true, this is the width of the screen (not the case)
  height,
  subject,
  mobileCaseBackgroundColor,
  useHTMLMobileCase = false,
}: MobilePreviewTypes): JSX.Element => {
  // keep aspect ratio if height is not provided
  const heightToUse = height ? height : (width * 674) / 344; // default height is effectively 674px
  const Case = type === 'whatsapp' ? MobileCaseWhatsapp : MobileCase;
  const content =
    type === 'web' ? (
      useHTMLMobileCase ? (
        message
      ) : (
        <div className='web-content'>
          <WebPreviewer content={message} />
        </div>
      )
    ) : (
      typeof message === 'string' && (
        <div className='text-bubble'>
          {media && media}
          {/* Injecting input (as markup) to render as html in order to pring 
            carriage-returns in the UI mobile preview display */}
          <DraftailPreview htmlContentString={carriageReturnDisplay(message)} />
        </div>
      )
    );

  return (
    <MobilePreviewWrapper
      width={useHTMLMobileCase ? width + CASE_WIDTH_OFFSET : width}
      height={
        useHTMLMobileCase ? heightToUse + CASE_HEIGHT_OFFSET : heightToUse
      }
      mobileCaseBackgroundColor={mobileCaseBackgroundColor}
    >
      {!useHTMLMobileCase ? (
        <>
          <Case className='mobile-case' />
          <div className={['scroll-wrapper', type].join(' ')}>
            {subject && <Subject subject={subject} />}
            {content}
          </div>
        </>
      ) : (
        <HTMLMobileCase
          width={width}
          height={heightToUse}
          content={content}
          backgroundColor={mobileCaseBackgroundColor}
          header={subject && <Subject subject={subject} />}
        />
      )}
    </MobilePreviewWrapper>
  );
};
