import styled from 'styled-components';
import { Colors } from '../../Foundation/Colors';
import { transitionStyle } from '../../Foundation/Animations';

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  width: 70px;
  height: 40px;

  background: ${Colors.white};

  border: 1px solid ${Colors.grey_blue_4};
  box-sizing: border-box;
  border-radius: 8px;

  &:hover {
    cursor: pointer;
  }

  transition: all ${transitionStyle};
`;

export const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;

  svg {
    fill: ${Colors.grey_blue_4};
    height: 20px;
    width: 20px;
  }

  &:hover {
    svg {
      fill: ${Colors.accent_5};
    }
  }
`;
