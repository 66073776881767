import styled, { css } from 'styled-components';

const headerHeightPx = 174;

export const MobilePreviewWrapper = styled.div`
  padding-top: 36px;
  display: flex;
  justify-content: center;
`;

export const DesktopPreviewWrapper = styled.div<{ height?: number }>`
  padding-top: 16px;
  display: flex;
  justify-content: center;
  height: calc(100vh - ${headerHeightPx}px);
  margin: 0 auto;
  ${({ height }) =>
    height &&
    css`
      min-height: ${height}px;
    `}
`;

export const TabWrapper = styled.div<{ height?: number }>`
  height: unset;
  ${({ height }) =>
    height &&
    css`
      .MuiTypography-root {
        min-height: ${height}px;
      }
    `}
`;
