import { FieldErrors, Path, UseFormRegister } from 'react-hook-form';
import React from 'react';
import { TextFieldProps } from '@material-ui/core';
import { GenericFormFieldAttributes } from '../ListForm.types';
import { StyledTextField } from '../ListForm.styles';

type GenericTextFieldProps<TFormDataType> = {
  name: Path<TFormDataType>;
  register?: UseFormRegister<TFormDataType>;
  errors: FieldErrors<TFormDataType>;
} & Omit<GenericFormFieldAttributes<TFormDataType>, 'fieldComponent'> &
  Omit<TextFieldProps, 'name' | 'ref' | 'color' | 'size'>;

export const GenericTextField = <TFormDataType,>({
  errors,
  register,
  name,
  ...rest
}: GenericTextFieldProps<TFormDataType>) => {
  const definedRegister = register ? register(name) : {};

  // See here for why we need to cast name as string:
  // https://github.com/react-hook-form/react-hook-form/issues/7040
  const isValid = !!(rest.format && (!errors || !errors[name as string]));

  return (
    <StyledTextField
      isValid={isValid}
      key={name}
      error={errors && errors[name as string]}
      helperText={errors && errors[name as string]?.message}
      fullWidth
      variant='outlined'
      aria-labelledby={rest.attribute as string}
      aria-describedby={rest.description}
      {...definedRegister}
      {...rest}
      // TODO: find a better solution to a clear button.
      // Changing the type to search works well but messes with accessibility and auto-complete.
      type='search'
    />
  );
};
