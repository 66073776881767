import { Select } from '@material-ui/core';
import styled from 'styled-components';
import { Colors } from '../../../../../Foundation/Colors';
import { Fonts } from '../../../../../Foundation/Fonts';

export const CountrySelectValueWrapper = styled.div`
  ${Fonts.body}
  color: ${Colors.grey_blue_4};
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: row;
  gap: 8px;

  .PhoneInputCountryIcon {
    display: flex;
    width: 24px;
    height: 100%;
  }
`;

export const StyledSelect = styled(Select)`
  width: 156px;
`;

export const DIVIDER_STYLE = {
  minHeight: '1px',
  fontSize: '1px',
  backgroundColor: Colors.grey_blue_1,
};
