import {
  DataTableProps,
  DataTableRowProps,
  RowData,
  ColumnData,
} from '../DataTable/DataTable.types';

export const SELECTION_STATE = {
  SOME: 'some-on-page',
  ALL: 'all-on-page',
  NONE: 'none-on-page',
  ALL_PAGES: 'all-on-all-pages',
};

export type {
  ActionButtonProps,
  RowData,
  SelectMode,
  BatchAction,
} from '../DataTable/DataTable.types';

/** to be used for graphql request (limit, offset, sorting) */
export type DataQueryParams = {
  page: number;
  rowsPerPage: number;
  columnToSort?: string;
  sortAscending?: boolean;
};

export type QueryDataTableColumnData<
  T extends RowData,
  K extends keyof T & string = keyof T & string
> = ColumnData<T, K> & {
  truncateLongText?: boolean;
  maxWidth?: string;
};

// TypeScript magic to enforce typing between the column 'field' key, and the row keys.
// https://stackoverflow.com/questions/64744734/typescript-keyof-index-type-is-too-wide
export type QueryDataTableTypedColumn<
  T extends RowData
> = keyof T extends infer K
  ? K extends keyof T & string
    ? QueryDataTableColumnData<T, K>
    : never
  : never;

export type QueryDataTableProps<T extends RowData> = Omit<
  DataTableProps<T>,
  'rowsPerPage'
> & {
  onTableViewChange: (props: DataQueryParams) => void; // change listener for DataQueryParams
  page?: number;
  rowsPerPage: number;
  totalRowsInAllPages?: number; // total number of item in all pages
  isDisabledSort?: boolean; // disable sorting
  selectOnRowClick?: boolean; // wether to tick the checkbox on row click
  defaultSortedColumn?: string;
  defaultSortAscending?: boolean;
  disableRow?: (row: T) => boolean;

  onSelectionChange?: (
    selectionState: SelectionState,
    selectedRows: Array<string | number>
  ) => void; // row selection change
};

export type QueryDataTableRowProps<T extends RowData> = Omit<
  DataTableRowProps<T>,
  'columns'
> & {
  columns: Array<QueryDataTableTypedColumn<T>>;
  selectOnRowClick?: boolean; // wether to tick the checkbox on row click
  isRowDisabled?: boolean;
};

export type SelectionState =
  | typeof SELECTION_STATE.NONE
  | typeof SELECTION_STATE.SOME
  | typeof SELECTION_STATE.ALL
  | typeof SELECTION_STATE.ALL_PAGES;

export type DataTableTypedColumn<
  T extends RowData
> = QueryDataTableTypedColumn<T> & {
  disableColumnSort?: boolean;
};

export type DefaultRenderMethodOptions = {
  truncateLongText?: boolean;
  maxWidth?: string;
};
